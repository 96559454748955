import { IonPage, IonContent } from "@ionic/react";

import "./WebSign.scss";
import Toolbar from "../components/Toolbar";
import { t } from "i18next";
import { RouteComponentProps } from "react-router";

interface ParamsDetails
  extends RouteComponentProps<{
    type: string;
    id: string;
  }> {}

const SignReport: React.FC<ParamsDetails> = ({ match }) => {
  const urlPath = `/WebSignSpecific/?reportType=${match.params.type}&reportId=${match.params.id}`;

  // TODO: learn how to import a static file from a public folder in ionic/react lol
  // made a script on /helpers/hookScript.js
  // declare var WebSign: any;
  // import WebSign from '../public/js/web-sign/web-sign.js;

  return (
    <IonPage>
      <Toolbar title={t("Firmar documentos")} />
      <IonContent fullscreen>
        {/* <script src="assets/js/test.js"></script> //here, not in body */}
        <div style={{height:'100%'}}>
          {/* not an iframe but a /api_portal/request_report/ */}
          {/* TODO: delete iframe & make it work without an iframe */}
          {/* smth like <SignDocument userId={userId} reportType={reportType} reportId={reportId} /> */}
          <iframe
            src={urlPath}
            name="websign-frame"
            title="Web Sign Frame"
            height="100%"
            width="100%"
          ></iframe>
        </div>
        <div></div>
      </IonContent>
    </IonPage>
  );
};

export default SignReport;
