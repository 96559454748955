import './Report.scss'
import { useState } from 'react';
import { OverlayEventDetail } from '@ionic/core/components';
import { useIonModal, IonIcon } from '@ionic/react';
import ModalReportRequest from './modals/ModalReportRequest';
import { calendarClearOutline, documentAttachOutline, imageOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const Report : any = (props : any)=>{
    const {id, report_type, main_reason, date, aptitudes, consultation_files, ecg_files, image_test_files, other_test_files, file_type} = props.rep

    const [reportInfo, setReportInfo] = useState<any>({
        report_type: "",
        report_id: "",
        report_name: ""
    })

    // Modal
    const [present, dismiss] = useIonModal(ModalReportRequest, {
        onDismiss: (data: string, role: string) => dismiss(data, role),
        reportInfo: reportInfo
      }, );
    
    function openModal({file_type, id, file_name}: {file_type:string, id:string, file_name:string}) {
        setReportInfo({
            report_type: file_type,
            report_id: id,
            report_name: file_name
        })
        present({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
            },
        },);
    }

    function getFileIcon( fileName : string ){
        const type = fileName.split('.').pop();
        let icon = documentAttachOutline;

        const imageExtensions  = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'bitmap', 'ico'];
        
        if( type ){
            if( imageExtensions.includes(type.toLowerCase()) ) icon = imageOutline;
        }

        return icon
    }

    const [t, i18n] = useTranslation("global");

    return(
        <div key={id} className="report">
            <div className="report__header">
                <div className="report__title">
                    <p>
                        {report_type}
                        <span className='report__date'>
                            <IonIcon aria-hidden="true" className='menu_report__icon' ios={calendarClearOutline} md={calendarClearOutline} />
                            { date?.split('T')[0].split('-')[2]}/{date?.split('T')[0].split('-')[1]}/{date?.split('T')[0].split('-')[0] }
                        </span>
                    </p>
                    <a download title={ t('Solicitar el archivo de') + ' ' + report_type} target="_blank" onClick={()=> openModal(props.rep)} className="report__button">
                        <IonIcon aria-hidden="true" className='report__icon' ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                    </a>
                </div>
            </div>
            <div className="report__body">
                <div className="report__props">
                    <div className="report__prop">
                        <div className="report__prop--title">{t('Motivo')}: </div>
                        <div className="report__prop--value">{main_reason}</div>
                    </div>
                    
                    {aptitudes && aptitudes?.length!==0 &&  
                        <div className="report__prop aptitudes">
                            <div className="report__prop--title">{t('Resultado')}:</div>
                            {
                                aptitudes.map((aptitude:any, index:number) => {
                                    return(
                                        <div className="report__results" key={index}>
                                            <p className="report__result"><span>{aptitude.workstation}</span> {aptitude.name}</p>
                                            {aptitude.conditionings &&
                                                <p className="report__result"><span>{t('Condicionantes')}:</span> {aptitude.conditionings}</p> 
                                            }
                                        </div>
                                    )
                                })
                            }
                            <a download title={ t('Solicitar el archivo de aptitud')} target="_blank" onClick={()=> openModal({file_type: "aptitudes", id: props.rep.id, file_name: "aptitud"})} className="report__file file">
                                <span className="file__icon">
                                    <IonIcon aria-hidden="true" className='report__icon' ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                                </span>
                                <p className="file__info">
                                    <span className="file__type">{t('Informe de aptitud')}</span>
                                    <span className='file__name'>{"aptitud.pdf"}</span>
                                </p>
                            </a>
                        </div> 
                    }
                    
                    {consultation_files?.map((file: any) => (
                        <a download title={t('Solicitar el archivo adjunto de la consulta')} target="_blank" onClick={()=> openModal(file)} className="report__file file" key={file.id}> 
                            <span className="file__icon">
                                <IonIcon aria-hidden="true" ios={getFileIcon(file.file_name)} md={getFileIcon(file.file_name)}></IonIcon>
                            </span>
                            <p className="file__info">
                                <span className="file__type">{t('Archivo adjunto de la consulta')}</span>
                                <span className='file__name'>{file.file_name}</span>
                            </p>
                        </a>
                    ))}
                    {ecg_files?.map((file: any) => (
                        <a download title={t('Solicitar el Electrocardiograma')} target="_blank" onClick={()=> openModal(file)} className="report__file file" key={file.id}> 
                            <span className="file__icon">
                                <IonIcon aria-hidden="true" ios={getFileIcon(file.file_name)} md={getFileIcon(file.file_name)}></IonIcon>
                            </span>
                            <p className="file__info">
                                <span className="file__type">{t('Electrocardiograma')}</span>
                                <span className='file__name'>{file.file_name}</span>
                            </p>
                        </a>
                    ))}
                    {image_test_files?.map((file: any) => (
                        <a download title={t('Solicitar Test de Imagen')} target="_blank" onClick={()=> openModal(file)} className="report__file file" key={file.id}> 
                            <span className="file__icon">
                                <IonIcon aria-hidden="true" ios={getFileIcon(file.file_name)} md={getFileIcon(file.file_name)}></IonIcon>
                            </span>
                            <p className="file__info">
                                <span className="file__type">{t('Test de Imagen')}</span>
                                <span className='file__name'>{file.file_name}</span>
                            </p>
                        </a>
                    ))}
                    {other_test_files?.map((file: any) => (
                        <a download title={t('Solicitar el archivo de otras pruebas complementarias')} target="_blank" onClick={()=> openModal(file)} className="report__file file" key={file.id}> 
                            <span className="file__icon">
                                <IonIcon aria-hidden="true" ios={getFileIcon(file.file_name)} md={getFileIcon(file.file_name)}></IonIcon>
                            </span>
                            <p className="file__info">
                                <span className="file__type">{t('Otras pruebas complementarias')}</span>
                                <span className='file__name'>{file.file_name}</span>
                            </p>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}



export default Report