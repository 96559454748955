import { useEffect, useState } from "react"
import { IonPage, IonContent, IonButton, useIonPicker, IonIcon } from "@ionic/react"

// ES6 module
// import Plotly from 'plotly.js'
// import locale from 'plotly.js-locales/fr'

// then
// Plotly.register(locale)
// Plotly.setPlotConfig({locale: 'fr'})

import Plot from 'react-plotly.js';
import "plotly.js/lib/locales/es.js";

import './Misalud.css'

import { chevronDown } from 'ionicons/icons';
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { selectHistoricalVariables } from "../features/historicalVariables/historicalVariablesSlice";
import { getHistoricalVariables } from "../actions/historicalVariables";
import { useTranslation } from "react-i18next";
import Toolbar from "../components/Toolbar";

const Misalud = ()=>{

    const [t] = useTranslation("global");
    let plotWidth

    if( window.innerWidth > 992 ){
        plotWidth = window.innerWidth * 0.72 - 32
    }else{
        let padding = ( window.innerWidth / 100 ) * 5
        plotWidth = window.innerWidth - padding * 2
    }

    const [present] = useIonPicker();
    const [plotChoice, setPlotChoice] = useState<any>(0)
    const dispatch = useAppDispatch()
    const historicalVariables = useAppSelector(selectHistoricalVariables)


    const openPicker = async () => {
        const plotChoiceOptions = historicalVariables.map((variable, index) => {
            return (
                {
                    text: `${t(variable.title)}`,
                    value: index
                }
            )
        })
        present({
          columns: [
            {
              name: 'variables',
              options: plotChoiceOptions,
              selectedIndex: plotChoice
            },
          ],
          buttons: [
            {
              text: `${t('Cancelar')}`,
              role: 'cancel',
            },
            {
              text: `${t('Confirmar')}`,
              handler: (value) => {
                setPlotChoice(value.variables.value);
              },
            },
          ],
        });
      };
    

    useEffect(() => {
        dispatch(getHistoricalVariables())
    }, [])

    return(
        <IonPage>
            <Toolbar title={t('Mi Salud')} />
            <IonContent fullscreen>
                <div className="plots">
                <IonButton className="plots__select-button" onClick={openPicker} defaultValue={plotChoice}>
                    <span>{historicalVariables && t(historicalVariables[plotChoice]?.title)}</span>
                    <IonIcon slot="end" ios={chevronDown} md={chevronDown} />                  
                </IonButton>
                    <div className="plots__container">
                    { historicalVariables && historicalVariables[plotChoice]?.title &&
                    
                    historicalVariables ? 
                        <Plot
                            data={
                                historicalVariables[plotChoice]?.values.map(value => {
                                    return (
                                        {//COLESTEROL TOTAL
                                            'x': value.x,
                                            'y': value.y,
                                            'name': `${t(value.name)}`,
                                            'type': 'scatter',
                                            'showlegend': true,
                                            'marker': {
                                                'size': 7,
                                                // 'color': value.color,
                                            },
                                            'line': {
                                                'dash': 'dot',
                                            },
                                        }
                                    )
                                })
                            } 
                            layout={{
                                dragmode:false,
                                // width: plotWidth,
                                height: window.innerWidth > 900 ? plotWidth / 1.9 : window.innerWidth > 400 ? plotWidth * 1.2 : plotWidth * 1.7,
                                autosize: true,
                                legend:{
                                    'orientation':"h",
                                    'yanchor':"bottom",
                                    'y':0.95,
                                    'xanchor':"right",
                                    'x':1
                                },
                                'margin': {
                                    'l'  : !historicalVariables[plotChoice]?.yaxis.title ? 20 : window.innerWidth > 992 ? 50 : 40,
                                    'r'  : 10,
                                    'b'  : window.innerWidth > 992 ? 60 : 50,
                                    't'  : 30,
                                    'pad': 0,
                                },
                                'plot_bgcolor': '#00000000',
                                'yaxis': {
                                    title: {
                                        text: `${t(historicalVariables[plotChoice]?.yaxis.title)}`,
                                        font: {
                                            family: 'Courier New, monospace',
                                            size: 17,
                                            color: '#7f7f7f'
                                        }
                                    },
                                    'range': historicalVariables[plotChoice]?.yaxis.range,
                                    'gridcolor': '#eee',
                                    'tickmode': 'array',
                                    'tickvals': historicalVariables[plotChoice]?.yaxis.tickvals,
                                    'ticktext': historicalVariables[plotChoice]?.yaxis.ticktext,
                                },
                                'xaxis':{
                                    title: {
                                        text: `${t(historicalVariables[plotChoice]?.xaxis.title)}`,
                                        font: {
                                            family: 'Courier New, monospace',
                                            size: 17,
                                            color: '#7f7f7f'
                                        }
                                    },
                                    'gridcolor': '#eee',
                                    // 'type': 'log',
                                    'tickmode': 'array',
                                    // 'tickvals': [2018, 2019, 2020, 2021],
                                }
                            }}

                            useResizeHandler={true}
                            style={{width: '100%', height: '100%'}}

                            config={{scrollZoom:false, displayModeBar:true, modeBarButtonsToRemove:['toImage', 'zoom2d', 'zoomIn2d', 'zoomOut2d', 'pan2d', 'select2d', 'lasso2d', ], displaylogo:false, locale: 'es',  responsive:true}}
                        />
                    : null}
                    {/* <div className="plots__title">Última audiometría</div>
                    <Plot 
                    data={[
                        {//IZQUIERDO AZUL AEREA
                            'x': [250, 500, 1000, 2000, 4000, 8000],
                            'y': [60, 68, 63, 72, 65, 75],
                            'name': 'Oído Izquierdo Aérea',
                            'type': 'scatter',
                            'marker': {
                                'size': 7,
                                'color': '#1f63e2',
                            }
                        },
                        {//DERECHO ROJO AEREA
                            'x': [250, 500, 1000, 2000, 4000, 8000],
                            'y': [65, 73, 70, 76, 70, 81],
                            'name': 'Oído Derecho Aérea',
                            'type': 'scatter',
                            'marker': {
                                'size': 7,
                                'color': '#dc3545',
                            },

                        },
                        {//IZQUIERDO AZUL OSEA
                            'x': [250, 500, 1000, 2000, 4000, 8000],
                            'y': [10, 12, 15, 20, 32, 41],
                            'name': 'Oído Izquierdo Ósea',
                            'type': 'scatter',
                            'marker': {
                                'symbol': 'triangle-up',
                                'color': '#1f63e2',
                                'size': 12,
                            },
                            'line': {
                                'dash': 'dot',
                            },
                        },
                        {//DERECHO ROJO OSEA
                            'x': [250, 500, 1000, 2000, 4000, 8000],
                            'y': [10, 15, 17, 24, 36, 45],
                            'name': 'Oído Derecho Ósea',
                            'type': 'scatter',
                            'marker': {
                                'symbol': 'triangle-up',
                                'size': 12,
                                'color': '#dc3545'
                            },
                            'line': {
                                'dash': 'dot',
                            },
                        },
                    ]} 

                    layout={{
                        dragmode:false,
                        // width: plotWidth,
                        height: window.innerWidth > 900 ? plotWidth / 1.9 : window.innerWidth > 400 ? plotWidth * 1.2 : plotWidth * 1.7,
                        autosize: true,
                        legend:{
                            'orientation':"h",
                            'yanchor':"bottom",
                            'y':1,
                            'xanchor':"right",
                            'x':1
                        },
                        'margin': {
                            'l'  : window.innerWidth > 992 ? 50 : 40,
                            'r'  : 10,
                            'b'  : window.innerWidth > 992 ? 50 : 40,
                            't'  : window.innerWidth > 760 ? 60 : window.innerWidth > 400 ? 70 : 110,
                            'pad': 0,
                        },
                        'plot_bgcolor': 'rgba(0,0,0,0)',
                        'yaxis': {
                            title: {
                                text: 'Intensidad (dB)',
                                font: {
                                    family: 'Courier New, monospace',
                                    size: window.innerWidth > 992 ? 18  : 14,
                                    color: '#7f7f7f'
                                }
                            },
                            'range': [ 120, -10],
                            'gridcolor': '#eee',
                            'tickmode': 'array',
                            'tickvals': [-10, 0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
                            'ticktext': ["-10", "0", "10", "20", "30", "40", "50", "60", "70", "80", "90", "100", "110", "120"],
                        },
                        'xaxis':{
                            title: {
                                text: 'Frecuencia (Hz)',
                                font: {
                                    family: 'Courier New, monospace',
                                    size: window.innerWidth > 992 ? 18  : 14,
                                    color: '#7f7f7f'
                                }
                            },
                        },
                    }}

                    useResizeHandler={true}
                    style={{width: '100%', height: '100%'}}

                    config={{scrollZoom:false, displayModeBar:true, modeBarButtonsToRemove:['toImage', 'zoom2d', 'zoomIn2d', 'zoomOut2d', 'pan2d', 'select2d', 'lasso2d', ], displaylogo:false, responsive:true }}
                    /> */}
                    </div>
                </div>
                {/* <div className="loss">
                    <div className="loss__wrap">
                        <div className="loss__item">
                            <div className="loss__text">
                                <div className="loss__title">Oído izquierdo</div>
                                <div className="loss__value">-8%</div>
                            </div>
                            <div className="loss__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="loss__svg" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                </svg>
                            </div>
                        </div>
                        <div className="loss__item">
                            <div className="loss__text">
                                <div className="loss__title">Oído derecho</div>
                                <div className="loss__value">-3%</div>
                            </div>
                            <div className="loss__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="loss__svg" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                </svg>
                            </div>
                        </div>
                        <div className="loss__item">
                            <div className="loss__text">
                                <div className="loss__title">Global</div>
                                <div className="loss__value">-4%</div>
                            </div>
                            <div className="loss__icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="loss__svg" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div> */}

            </IonContent>
        </IonPage>
    )
}

export default Misalud