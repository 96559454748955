import axios from "axios"
import { initialAppointments } from "../features/appointment/appointmentSlice";
import { setWorkerInfo } from "../features/worker/workerSlice";
import { tokenConfig } from '../helpers/setAuthToken';
import { setExpiredSession } from "../helpers/expiredSession"

export interface Worker {
    name: string;
    encrypted_personal_data: PersonalData;
    full_address: FullAddress;
    health_appointments: HealthAppointments[];
    health_reports: HealthReports[];
    sign_reports: SignReports[];
    active_periods: String[];
    worker_profile_picture: string;
};

interface DataResponse {
    data: Worker;
    success: Boolean;
}

interface FullAddress {
    bis: number;
    block: string;
    door: string;
    flat: string;
    locality: string;
    number: string;
    postal_code: string;
    province: string;
    stair: string;
    street: string;
    street_type: string;
    town: string;
}

interface PersonalData {
    email: string;
    birthday: string;
    name: string;
    first_surname: string;
    second_surname: string;
    mobile_phone: string;
    work_phone: string;
    phone: string;
    image: string;
}

export interface HealthAppointments {
    id: number;
    appointment_type: string;
    appointment_decline_reason: string;
    date: string;
    main_reason: string;
    medic: string;
    medical_center: string;
    status: string;
}

export interface IConsultationFiles {
    id: number;
    file_name: string;
    file_type: string;
}

interface HealthReports {
    // General
    id: number;
    file_type: string;
    // MedicalConsultations
    report_type: string;
    date: string;
    free_advices: any;
    health_tech: string;
    main_reason: string;
    medical_center: string;
    status: string;
    aptitudes: any[];
    consultation_files: IConsultationFiles[];
    ecg_files: any[];
    image_test_files: any[];
    other_test_files: any[];
    // Aptitudes
    aptitude: string;
    aptitude_type: string;
    start_date: string;
    end_date: string;
    workstation: string;
    // MedicalHistoryDocuments
    document_name: string;
    document_type: string;
    signing_date: string;
}

interface SignReports {
    // General
    id: number;
    name: string;
    // delivery_reason: string;
    // units: number;
    // file: string;
    // notes: string;
    // expiration_date: string;
    // date: string;
    /*
        'id': item.id,
        'name': item.__str__(),
        'delivery_reason': item.delivery_reason,
        'units': item.units,
        'file': item.file,
        'notes': item.notes,
        'expiration_date': item.expiration_date,
        'date': item.date,
    */
}

export const getWorkerInfo = () => async (dispatch:any) => {
    try {
        const serverUrl = localStorage.getItem("server_url")

        const { data } = await axios.get<DataResponse>( 
            `${serverUrl}/api/worker_profile/`,
            tokenConfig()
        );

        dispatch(setWorkerInfo(data['data']))
        dispatch(initialAppointments (data['data']['health_appointments']))

    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            setExpiredSession()
            return error.message;
        } else {
            console.log('unexpected error: ', error);
            return 'An unexpected error occurred';
        }
    }
}

// export const changeProfilePicture = (props:any) => async (dispatch:any) => {
//     try {
//         const serverUrl = localStorage.getItem("server_url")

//         // TODO: Change
//         const { data } = await axios.get<DataResponse>( 
//             `${serverUrl}/api/worker_profile/`,
//             tokenConfig()
//         );

//         dispatch(setWorkerProfilePicture(props))

//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.log('error message: ', error.message);
//             setExpiredSession()
//             return error.message;
//         } else {
//             console.log('unexpected error: ', error);
//             return 'An unexpected error occurred';
//         }
//     }
// }