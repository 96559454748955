import "./ModalCreateNotification.scss"

import { IonButton, IonButtons, IonHeader, IonIcon, IonInput, IonLabel, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToolbar, useIonModal, } from '@ionic/react';
import { addCircleOutline, alertCircleOutline, bandageOutline, cameraOutline, checkmarkCircleOutline, closeOutline, documentAttachOutline, imageOutline, listOutline } from 'ionicons/icons';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useFilePicker, usePhotoGallery, useAppSelector } from "../../app/hooks";
import { OverlayEventDetail } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import ModalNotificationFiles from "./ModalNotificationFiles";
import { INotificationFile } from "../../features/notification/interfaces";
import { addNotification } from "../../actions/notficationsManagement";
import { selectDepartments } from '../../features/notification/notificationSlice';


const ModalCreateNotification = ({
    onDismiss,
    }: {
        onDismiss: (data?: string | null | undefined | number, role?: string) => void;
        userInfo : any
    }) => {       

    const [t] = useTranslation("global");

    const notificationTypes = [
        { name : 'Accidente', value : 'accident'    , icon : bandageOutline         },
        { name : 'Riesgo'   , value : 'risk'        , icon : alertCircleOutline     },
        { name : 'Mejora'   , value : 'improvement' , icon : checkmarkCircleOutline },
        { name : 'Otro'     , value : 'other'       , icon : listOutline            },
    ]

    const departments = useAppSelector(selectDepartments);

    function openAddFilesModal(){
        const fileInput : HTMLElement = document.getElementById('add-files') as HTMLElement
        fileInput.click()
    }
    
    const { photos, setPhotos, takePhoto } = usePhotoGallery();
    const { images, setImages, documents, setDocuments, pickFile } = useFilePicker();

    const [activeNotificationFiles, setActiveNotificationFiles] = useState({})
    
    const [presentNotificationFiles, dismissNotificationFiles] = useIonModal(ModalNotificationFiles, {
        data: activeNotificationFiles,
        onDismiss: (data: INotificationFile[], role: string) => handleDismiss( data )
    }, );

    function handleDismiss( data : INotificationFile[] ){
            if( data.length > 0 ){
                let newImages    : INotificationFile[] = [...images]
                let newPhotos    : INotificationFile[] = [...photos]
                let newDocuments : INotificationFile[] = [...documents]

                data.map( ( file : INotificationFile ) => {
                    newImages    = newImages.filter   ( image    => image.name    !== file.name && image.data    !== file.data )
                    newPhotos    = newPhotos.filter   ( photo    => photo.name    !== file.name && photo.data    !== file.data )
                    newDocuments = newDocuments.filter( document => document.name !== file.name && document.data !== file.data )
                })

                setImages( newImages )
                setDocuments( newDocuments )
                setPhotos( newPhotos )
            }

            dismissNotificationFiles()
    }

    function openNotificationFilesModal( data : any ) {
        setActiveNotificationFiles( data )

        presentNotificationFiles({
            onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {},
        },);
    } 

    const dispatch = useAppDispatch()

    function formatDate( date : Date ){
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();

        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedHour = hour < 10 ? '0' + hour : hour;
        const formattedMinute = minute < 10 ? '0' + minute : minute;

        return `${year}-${formattedMonth}-${formattedDay} ${formattedHour}:${formattedMinute}`;
    }

    function handleFormSubmit( e : React.SyntheticEvent ){
        e.preventDefault();
        const form     = e.target as HTMLFormElement;
        const formData = new FormData(form);

        const typeValue       = formData.get('type')       as string;
        const departmentValue = formData.get('department') as string;

        const type       = notificationTypes.find( t => t.value === typeValue )!.name
        const department = departments.find( t => t.id == departmentValue )
        const subject    = formData.get('subject') as string;
        const message    = formData.get('message') as string;

        const sent_datetime = formatDate( new Date() )

        const newNotification = {
            type,
            subject,
            message,
            department,
            sent_datetime,
            images   : [ ...images, ...photos ],
            files    : documents,
            received : false,
            answer   : ''
        }

        dispatch(addNotification( newNotification ))
        onDismiss(null, 'added')
    }


    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton className='user-profile__close-button' title={t('Salir')} color="medium" onClick={() => onDismiss(null, 'cancel')}>
                <IonIcon className='user-profile__close' ios={closeOutline} md={closeOutline}/>
              </IonButton>
            </IonButtons>
            <IonTitle>{t('Crear una notificación')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className="ion-padding create-notif">
            <form action="" onSubmit={(e) => handleFormSubmit(e)} className='modal-form'>
                <ul className="modal-form__ul">
                    <li>
                        <IonLabel>{t('Típo')}*</IonLabel>
                        <ul className="icon-select">
                            {
                                notificationTypes && notificationTypes.map(( type, i ) => (
                                    <li key={i} className={`icon-select__li ${ type.name.length > 10 && type.name.length <= 20 ? 'icon-select__li--medium' : type.name.length > 20 && type.name.length <= 40 ? 'icon-select__li--long' : type.name.length > 40 && type.name.length <= 50 ? 'icon-select__li--huge' : type.name.length > 50 ? 'icon-select__li--wrap' : '' }`}>
                                        <label>
                                            <input type="radio" name='type' value={ type.value } id={`id-${ type.value }`} />
                                            <span className="icon-select__styled"><span className="icon-select__styled-icon"><IonIcon ios={ type.icon } md={ type.icon }></IonIcon></span><span className="icon-select__styled-name">{ type.name }</span></span>
                                        </label>
                                    </li>
                                ))
                            }
                        </ul>
                    </li>
                    <li>
                        <IonLabel>{t('Departamento')}*</IonLabel>
                        <IonSelect name="department" className='modal-form__select' okText={t('Seleccionar Departamento')} cancelText={t('Cancelar')} labelPlacement="floating" placeholder="Nombre del Departamento">
                            {
                                departments.map(( department, i ) => {
                                    return <IonSelectOption key={i} value={ department.id }>{ department.name }</IonSelectOption>
                                })
                            }
                        </IonSelect>
                    </li>
                    <li>
                        <IonLabel>{t('Asunto')}*</IonLabel>
                        <IonInput required name="subject" counter={true} maxlength={256} className='modal-form__input' placeholder='Asunto de la Comunicación'></IonInput>
                    </li>
                    <li>
                        <IonLabel>{t('Mensaje')}*</IonLabel>
                        <IonTextarea required name="message" counter={true} maxlength={30000} autoGrow={true} className='modal-form__textarea' placeholder="Escribe tu mensaje"></IonTextarea>
                    </li>
                    <li className="file-input">
                        <button title='Hacer foto (Opcional)' onClick={ takePhoto } type='button' className="button-base modal-form__take-photo">
                            <IonIcon ios={cameraOutline} md={cameraOutline}/>
                            Hacer Fotos
                        </button>
                        <button title='Añadir Archivos Adjuntos (Opcional)' onClick={ pickFile } type='button' className="button-base modal-form__add-files">
                            <IonIcon ios={addCircleOutline} md={addCircleOutline}/>
                            Añadir Archivos Adjuntos
                        </button>
                        
                        { ( images.length > 0 || photos.length > 0 ) &&
                            (
                                <div className="file" onClick={() => { openNotificationFilesModal({ type: 'images', canDelete: true, files: [ ...images , ...photos ] }) }}> 
                                    <span className="file__icon">
                                        <IonIcon aria-hidden="true" ios={imageOutline} md={imageOutline}></IonIcon>
                                    </span>
                                    <p className="file__info">
                                        <span className="file__type">{images.length + photos.length} {t('imágenes adjuntas')}</span>
                                        <span className='file__name'>{t('Ver imágenes')}</span>
                                    </p>
                                </div>
                            )
                        }
                        { documents.length > 0 &&
                            (
                                <div className="file" onClick={() => { openNotificationFilesModal({ type: 'documents', canDelete: true, files: documents }) }}> 
                                    <span className="file__icon">
                                        <IonIcon aria-hidden="true" ios={documentAttachOutline} md={documentAttachOutline}></IonIcon>
                                    </span>
                                    <p className="file__info">
                                        <span className="file__type">{documents.length} {t('documentos adjuntos')}</span>
                                        <span className='file__name'>{t('Ver documentos')}</span>
                                    </p>
                                </div>
                            )
                        }
                    </li>
                </ul>
                <button className="button-base solid modal-form__submit" type='submit' title={t('Enviar Notificación')}>{t('Enviar Notificación')}</button>
            </form>
        </div>
      </IonPage>
    );
  };


  export default ModalCreateNotification